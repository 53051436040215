import { Component, Inject, OnDestroy, ElementRef, AfterViewChecked } from '@angular/core';
import { OnInit } from '@angular/core';

import * as Ng1Services from '../../../core/hybrid/ng1-upgraded-providers';
import { Router, ActivatedRoute } from '@angular/router';
import { SidebarService } from './sidebar.service';
import { UserProfileService } from '../../../core/context/user-profile.service';
import { environment } from '../../../../environments/environment';
import { BonusParticipationDetails } from '../../../shared/models/bonus-participation-details.model';
import { Store } from '@ngrx/store';
import { BonusDocumentConfig, BonusState, FilterState, selectBonusState, selectFiltersState, selectUserProfileState, UserProfile } from '@mint-libs/context';
import { FiltersService } from '../../../filters/filters.service';
import { CommonFactory } from '@mint-libs/common';
import { RoleFeatureService } from '@mint-libs/context';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { ImpersonationService } from 'src/app/core/impersonation/impersonation.service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';
import { AuthenticationService } from '@mint-libs/authentication';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewChecked {
  public filterService: any;
  isDashboardVisible: any;
  isDashboardActive: boolean;
  isReportActive: boolean;
  isEstimatorActive: boolean;
  isMyCompensationPlanActive: boolean;
  isComplianceActive: boolean;
  isHelpActive: boolean;
  isSettingsActive: boolean;
  isReportsVisible: any;
  isEstimatorVisible: any;
  isMyCompensationPlanVisible: any;
  isQAVisible: any;
  isMic2ReportsVisible: any;
  isMic2ReportsActive: any;
  showNotes = false;
  state: string;
  userProfileInfo: any;
  reportSubmenuList: any[];
  compSubmenuList: any[];
  helpSubmenuList: any[];
  submenuList: any[];
  submenuObj: any;
  isAIvisible: boolean;
  isAIActive: boolean;
  userProfileChangeSubscription: any;
  isUBIReportsVisible: boolean;
  isNgReportsActive: boolean;
  ngReportSubmenuList: any[];
  isCagrVisible = false;
  isCagrActive = false;
  isBonusVisible = false;
  isMQCHVisible = false;
  mchUserAliases = [];
  bonusPrograms = [];
  isNewReportsBeta = false;
  isBonusSubMenuActive = false;
  isConsolidatedBonusViewActive = false;
  isReportsSubMenuActive = false;
  isComplianceSubMenuActive = false;
  isHelpSubMenuActive = false;
  bonusState: BonusState = new BonusState();
  bonusConfig: BonusDocumentConfig[] = [];
  public sharedContext: SharedContext = new SharedContext();
  userProfileName = '';

  constructor(
    public sharedContextState: Store<SharedContext>,
    public filterState: Store<FilterState>,
    private authService: AuthenticationService,
    public userProfileService: UserProfileService,
    private impersonationService: ImpersonationService,
    public rolefeatureService: RoleFeatureService,
    private commonFactory: CommonFactory,
    private navigationService: NavigationService,
    filterService: FiltersService,
    private router: Router,
    public sidebarService: SidebarService,
    private element: ElementRef,
    private bonusStore: Store<BonusState>
  ) {
    this.navigationService = navigationService;
    var reportsList = this.navigationService.getNgReportsSubMenuList();
    this.filterState.select(selectFiltersState).subscribe(filterState => {
      this.ngReportSubmenuList = [];
      reportsList.forEach(report => {
        if (!filterState.is2025OrFuture || !report.name.includes('Actual')) {
          this.ngReportSubmenuList.push(report);
        }
      });
    });

    this.compSubmenuList = this.navigationService.compSubmenuList;
    this.helpSubmenuList = this.navigationService.helpSubmenuList;
    this.submenuList = this.navigationService.submenuList;
    this.submenuObj = this.navigationService.submenuObj;
    this.filterService = filterService;

    this.setupBonusStore();
    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
      this.isAIvisible = this.sharedContext.isACREligible;
      this.setFeatureVisibility();
    });
    this.navigationService.markAllMenuList();
  }
  ngAfterViewChecked(): void {
    // adding alt text to ngx-avatar
    const avtarImg = this.element.nativeElement.querySelector('ngx-avatar img');
    if (avtarImg) {
      avtarImg.setAttribute('alt', this.userProfileInfo.name);
    }
  }
  ngOnInit() {
    this.userProfileInfo = this.sharedContext.userProfileInfo;
    this.setFeatureVisibility();
    // this.configureUserVoice();
    this.sidebarService.setFeatureActivation();

    // Subscribe to event so that if any change happens in user profile due to compliance doc acknowledge or any thing
    // then reverify the visiblity of menus
    this.userProfileChangeSubscription = this.userProfileService.onUserProfileChange.subscribe(() => {
      this.setFeatureVisibility();
    });
  }

  ngOnDestroy() {
    if (this.userProfileChangeSubscription) {
      this.userProfileChangeSubscription.unsubscribe();
    }
  }

  setupBonusStore() {
    this.bonusStore.select(selectBonusState).subscribe((bonusState: BonusState) => {
      this.bonusState = bonusState;
      this.bonusConfig = bonusState.getBonusConfig();
    });
  }

  isBonusSelected(bonus: BonusDocumentConfig) {
    return bonus.id === this.bonusState.getSelectedBonusDocumentId() && this.sidebarService.isBonusActive;
  }

  setFeatureVisibility() {
    // Mic2 flag.
    this.isMic2ReportsVisible = this.filterService.isMic2Profile();
    this.isReportsVisible =
      !this.isMic2ReportsVisible &&
      !this.sharedContext.IsNonParticipantManager &&
      this.sharedContext.isRollOver === true &&
      ((this.sharedContext.isQuotaAcknowledged && this.sharedContext.areAllTrainingsCompleted) || this.sharedContext.isImpersonating) &&
      this.rolefeatureService.isPageApplicable(this.rolefeatureService.pages.Reports);
    this.isDashboardVisible =
      !this.isMic2ReportsVisible &&
      (this.sharedContext.isManager || this.sharedContext.isRollOver === true || this.sharedContext.isImpersonating) &&
      ((this.sharedContext.isQuotaAcknowledged && this.sharedContext.areAllTrainingsCompleted) ||
        this.sharedContext.isImpersonating ||
        this.sharedContext.isManager ||
        this.sharedContext.IsNonParticipantManager) &&
      this.rolefeatureService.isPageApplicable(this.rolefeatureService.pages.Dashboard);
    this.isEstimatorVisible =
      !this.isMic2ReportsVisible &&
      !this.sharedContext.IsNonParticipantManager &&
      !this.sharedContext.IsNonParticipantManagerForced &&
      this.sharedContext.isRollOver === true &&
      ((this.sharedContext.isQuotaAcknowledged && this.sharedContext.areAllTrainingsCompleted) || this.sharedContext.isImpersonating) &&
      this.rolefeatureService.isPageApplicable(this.rolefeatureService.pages.Estimator) &&
      this.userProfileService.isRBIPersona();
    this.isMyCompensationPlanVisible =
      !this.isMic2ReportsVisible &&
      !this.sharedContext.IsNonParticipantManager &&
      !this.sharedContext.IsNonParticipantManagerForced &&
      !this.sharedContext.IsNonParticipantManagerForced &&
      this.rolefeatureService.isPageApplicable(this.rolefeatureService.pages.MyCompensationPlan) &&
      (!this.sharedContext.QuotaFCAApprovalPending || this.sharedContext.isImpersonating);
    this.isQAVisible =
      !this.isMic2ReportsVisible &&
      !this.sharedContext.IsNonParticipantManager &&
      !this.sharedContext.IsNonParticipantManagerForced &&
      ((this.sharedContext.isDemoMode3 && this.sharedContext.isImpersonating) || !this.sharedContext.isDemoMode3);
    this.isCagrVisible = !this.isMic2ReportsVisible && this.sharedContext.IsCagrEligible && this.rolefeatureService.isPageApplicable(this.rolefeatureService.pages.Cagr);
    // Check added to enable CAGR
    // this.isCagrVisible = false;
    this.isMQCHVisible =
      this.checkMCHisEnabled() &&
      !this.isMic2ReportsVisible &&
      !this.sharedContext.IsNonParticipantManager &&
      !this.sharedContext.IsNonParticipantManagerForced &&
      this.sharedContext.isRollOver &&
      ((this.sharedContext.isQuotaAcknowledged && this.sharedContext.areAllTrainingsCompleted) || this.sharedContext.isImpersonating) &&
      this.userProfileService.isRBIPersona() &&
      this.filterService.isFSA();

    // let currentSelectedParticipationId = this.filterService.selectedStandardTitle.ParticipationID;
    // let currentSelectedFiscalYear = this.filterService.selectedFiscalYear;
    this.isConsolidatedBonusViewActive = this.checkForConsolidatedBonusView();
    // if (!this.isConsolidatedBonusViewActive) {
    //   this.userProfileService.getBonusEligiblityStatus(currentSelectedParticipationId, currentSelectedFiscalYear).subscribe(result => {
    //     if (result != undefined && result['length'] > 0) {
    //       result.forEach(program => {
    //         const bonusProgram = new BonusParticipationDetails().deserializeSingle(program);
    //         this.bonusPrograms.push(bonusProgram);
    //       });
    //       this.isBonusVisible = true;
    //     }
    //   });
    // }
  }

  checkFlightForMCH() {
    console.log('share context ', this.sharedContext);
    const result =
      this.filterService.isFSA() &&
      ((this.sharedContext.isImpersonating && (this.sharedContext.loggedInUserInfo.isSuperAdmin || this.sharedContext.loggedInUserInfo.isSupportAdmin || this.sharedContext.loggedInUserInfo.isFCA)) ||
        this.checkMCHisEnabled());
    console.log('result ', result);
    return result;
  }

  checkMCHisEnabled() {
    return !window.location.host.includes('microsoftfederal') && this.rolefeatureService.isPageApplicable(this.rolefeatureService.pages.MyChangeHistory);
  }

  checkForConsolidatedBonusView() {
    return this.rolefeatureService.isPageApplicable(this.rolefeatureService.pages.ConsolidatedBonus);
  }

  // isReportsActive(fragment) {
  //   return this.router.url.includes(fragment);
  // }

  navigateTo(item, id = null) {
    // if (item && item.indexOf('home') > -1 && id && id.indexOf('sidebar') > -1) {
    //   this.commonFactory.dismissAllSpinnersWithoutTimeout();
    // }

    if (item === 'bonus' && id) {
      this.sharedContext.currentBonusProgramId = id;
    }
    this.router.navigate([item], id ? { queryParams: { id: id } } : {}).then(_ => this.sidebarService.setFeatureActivation());
    this.state = item;
    // setTimeout(() => {
    //   this.sidebarService.setFeatureActivation();
    // }, 500);
    // this.navigationService.navigateTo(item, data).then(status => this.sidebarService.setFeatureActivation());
    // if (this.sidebarService.isSidebarActive) {
    //   this.sidebarService.isSidebarActive = false;
    // }
    this.showNotes = false;
  }

  expandSubMenu(e, viewname) {
    this.navigationService.markMenuList(viewname);
  }

  expandBonusSubMenu() {
    this.isBonusSubMenuActive = !this.isBonusSubMenuActive;
  }

  expandComplianceSubMenu() {
    this.isComplianceSubMenuActive = !this.isComplianceSubMenuActive;
  }

  expandHelpMenu() {
    this.isHelpSubMenuActive = !this.isHelpSubMenuActive;
  }

  expandReportsSubMenu() {
    this.isReportsSubMenuActive = !this.isReportsSubMenuActive;
  }

  logout() {
    this.authService.logOut();
  }

  getSidebarStatus() {
    return this.sidebarService.isSidebarActive;
  }

  navigateToSubView(view, submenu) {
    this.navigationService.navigateToSubView(view, submenu);
  }

  navigateToComplianceSubViews(submenuToBeActivated) {
    this.navigationService.resetActiveParamsOfAllMenus();
    this.compSubmenuList.forEach(item => {
      if (item.index === submenuToBeActivated.index) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    // window.location.href = 'complianceDashboard';
    this.router.navigate(['complianceDashboard']).then(response => this.sidebarService.setFeatureActivation());
  }

  navigateToNgSubView(view, submenu: any = null) {
    this.navigationService.resetActiveParamsOfAllMenus();
    if (submenu) {
      this.ngReportSubmenuList.forEach(item => {
        if (item.index === submenu.index) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    }
    // window.location.href = view;
    this.router.navigateByUrl(`/${view}${submenu ? `?id=${submenu.index}` : ''}`).then(result => {
      this.sidebarService.setFeatureActivation();
    });

    // this.router.navigate(['/reportsV2'], { queryParams: { id: submenu.index }, skipLocationChange: true , });
  }
}
